// extracted by mini-css-extract-plugin
export var frontContent = "FrontLayout-module--frontContent--+Qa5N";
export var frontpage = "FrontLayout-module--frontpage--oTjR4";
export var gridContainer = "FrontLayout-module--gridContainer--3rZGv";
export var knowitBlack = "#333";
export var knowitClay = "#a5b1aa";
export var knowitFlamingo = "#fac0b1";
export var knowitForest = "#4b6455";
export var knowitLollipop = "#f0f";
export var knowitMelon = "#fcdfd8";
export var knowitMint = "#dbeede";
export var knowitPear = "#b7debd";
export var knowitPebble = "#f1f0ed";
export var knowitPressed = "#b300b3";
export var knowitSand = "#e4e1db";
export var knowitWhite = "#f1f0ed";
export var palettePrimaryMain = "#b7debd";