exports.components = {
  "component---src-pages-2021-courses-design-index-js": () => import("./../../../src/pages/2021/courses/design/index.js" /* webpackChunkName: "component---src-pages-2021-courses-design-index-js" */),
  "component---src-pages-2021-courses-personalledelse-index-js": () => import("./../../../src/pages/2021/courses/personalledelse/index.js" /* webpackChunkName: "component---src-pages-2021-courses-personalledelse-index-js" */),
  "component---src-pages-2021-courses-strategi-og-ledelse-index-js": () => import("./../../../src/pages/2021/courses/strategi-og-ledelse/index.js" /* webpackChunkName: "component---src-pages-2021-courses-strategi-og-ledelse-index-js" */),
  "component---src-pages-2021-courses-teknologi-index-js": () => import("./../../../src/pages/2021/courses/teknologi/index.js" /* webpackChunkName: "component---src-pages-2021-courses-teknologi-index-js" */),
  "component---src-pages-2021-index-js": () => import("./../../../src/pages/2021/index.js" /* webpackChunkName: "component---src-pages-2021-index-js" */),
  "component---src-pages-2022-courses-cloud-index-js": () => import("./../../../src/pages/2022/courses/cloud/index.js" /* webpackChunkName: "component---src-pages-2022-courses-cloud-index-js" */),
  "component---src-pages-2022-courses-design-index-js": () => import("./../../../src/pages/2022/courses/design/index.js" /* webpackChunkName: "component---src-pages-2022-courses-design-index-js" */),
  "component---src-pages-2022-courses-personalledelse-index-js": () => import("./../../../src/pages/2022/courses/personalledelse/index.js" /* webpackChunkName: "component---src-pages-2022-courses-personalledelse-index-js" */),
  "component---src-pages-2022-courses-teknologi-index-js": () => import("./../../../src/pages/2022/courses/teknologi/index.js" /* webpackChunkName: "component---src-pages-2022-courses-teknologi-index-js" */),
  "component---src-pages-2022-index-js": () => import("./../../../src/pages/2022/index.js" /* webpackChunkName: "component---src-pages-2022-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-courses-cloud-index-js": () => import("./../../../src/pages/courses/cloud/index.js" /* webpackChunkName: "component---src-pages-courses-cloud-index-js" */),
  "component---src-pages-courses-design-index-js": () => import("./../../../src/pages/courses/design/index.js" /* webpackChunkName: "component---src-pages-courses-design-index-js" */),
  "component---src-pages-courses-personalledelse-index-js": () => import("./../../../src/pages/courses/personalledelse/index.js" /* webpackChunkName: "component---src-pages-courses-personalledelse-index-js" */),
  "component---src-pages-courses-teknologi-index-js": () => import("./../../../src/pages/courses/teknologi/index.js" /* webpackChunkName: "component---src-pages-courses-teknologi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-programs-template-js": () => import("./../../../src/templates/programsTemplate.js" /* webpackChunkName: "component---src-templates-programs-template-js" */)
}

